$primary-color: #000;
$primary-color-dark: #e6eaef;

$secondary-color: #fff;
$secondary-color-dark: #202124;

$secondary-1-color: #ffffffb3;
$secondary-1-color-dark: #fff;

$custom-secondary-color: #fff;
$custom-secondary-color-dark: #303033;

$primary-1-color: rgba($primary-color, 0.08);
$primary-1-color-dark: rgba($primary-color-dark, 0.08);

$custom-secondary-hover-color: #fffdfd;
$custom-secondary-hover-color-dark: rgb(65 67 70);

$gray-1-color: #333;
$gray-1-color-dark: #fff;

$gray-2-color: #4f4f4f;
$gray-2-color-dark: #eaedf0;

$gray-3-color: #828282;
$gray-3-color-dark: #f1f2f3;

$gray-4-color: #bdbdbd;
$gray-4-color-dark: #bdbdbd;

$gray-5-color: #e0e0e0;
$gray-5-color-dark: #5e5e60;

$gray-6-color: #f2f2f2;
$gray-6-color-dark: #5e5e60;

$gray-7-color: #f9f9f9;
$gray-7-color-dark: #202124;

$gray-8-color: #ccc;
$gray-8-color-dark: #ccc;

$gray-9-color: #ddd;
$gray-9-color-dark: #5f6368;

$gray-10-color: #eee;
$gray-10-color-dark: #303033;

$gray-11-color: #f3f3f3;
$gray-11-color-dark: #202124;

$gray-12-color: #ededed;
$gray-12-color-dark: #343437;

$gray-hero-color: #eee;
$gray-hero-color-dark: #202124;

$custom-gray-1-color: #aeaeae;
$custom-gray-1-color-dark: #aeaeae;

$custom-gray-8-color: rgba($gray-8-color, 0.85);
$custom-gray-8-color-dark: rgba($gray-8-color, 0.85);

$custom-gray-9-color: rgba($gray-9-color, 0.85);
$custom-gray-9-color-dark: #5f6368;

$custom-gray-3-color: rgba($gray-3-color, 0.73);
$custom-gray-3-color-dark: rgba($gray-3-color-dark, 0.73);

$green-1-color: #00a896;
$green-1-color-dark: #16d4bf;

$green-2-color: rgba($green-1-color, 0.8);
$green-3-color: rgba($green-1-color, 0.6);
$green-4-color: rgba($green-1-color, 0.4);
$green-5-color: rgba($green-1-color, 0.2);
$green-6-color: rgba($green-1-color, 0.1);

$green-2-color-dark: rgba($green-1-color, 0.8);
$green-3-color-dark: rgba($green-1-color, 0.6);
$green-4-color-dark: rgba($green-1-color, 0.4);
$green-5-color-dark: rgba($green-1-color, 0.2);
$green-6-color-dark: rgba($green-1-color, 0.1);


$info-color: #017fb9;
$info-color-dark: #58a6ff;

$success-color: #2eb244;
$success-color-dark: #2eb244;

$warning-color: #efd54b;
$warning-color-dark: #efd54b;

$danger-color: #e23326;
$danger-color-dark: #ea110d;

$danger-darker-color: #dc3545;
$danger-darker-color-dark: #f70c09;

$danger-lighter-color: #fe0000;
$danger-lighter-color-dark: #fe0000;

$info-hover-color: rgba($info-color, 0.7);
$info-hover-color-dark: rgba($info-color-dark, 0.8);

$fb-color: #3e68c0;
$fb-color-dark: #2950a0;

$reddit-color: #ff4500;
$reddit-color-dark: #ab3003;

$email-color: #7d7d7d;
$email-color-dark: #656363;

$twitter-color: #1b95e0;
$twitter-color-dark: #0f679c;

$fb-hover-color: rgba($fb-color, 0.9);
$fb-hover-color-dark: rgba($fb-color-dark, 0.9);

$reddit-hover-color: rgba($reddit-color, 0.9);
$reddit-hover-color-dark: rgba($reddit-color-dark, 0.9);

$email-hover-color: rgba($email-color, 0.9);
$email-hover-color-dark: rgba($email-color-dark, 0.9);

$twitter-hover-color: rgba($twitter-color, 0.9);
$twitter-hover-color-dark: rgba($twitter-color-dark, 0.9);

$box-shadow: 0px 0 0px rgb(255 255 255), 0 0px 0px rgb(255 255 255 / 25%), 0 4px 4px rgb(191 187 187 / 25%),
    4px 0 4px rgb(247 247 247 / 25%);
$box-shadow-dark: 0px 0 0px #2b2b2d, 0 0px 0px rgb(43 43 45), 0 1px 0px #5f6368, 0px 0 0px #5f6368;

$outer-shadow-1: -4px 0px 4px rgba(255, 255, 255, 0.25), 0px -4px 4px rgba(255, 255, 255, 0.25),
    0px 4px 4px rgba(189, 189, 189, 0.25), 4px 0px 4px rgba(189, 189, 189, 0.25);
$outer-shadow-1-dark: 0px 0px 0px #5f6368, 0px 0px 0px #5f6368, 0px 0px 1px #5f6368, 0px 0px 2px #5f6368;

$text-shadow: 0px -4px 4px rgba(255, 255, 255, 0.25), -4px 0px 4px rgba(255, 255, 255, 0.25),
    4px 0px 4px rgba(183, 183, 183, 0.25), 0px 4px 4px rgba(183, 183, 183, 0.25);
$text-shadow-dark: none;

$tranparent-border: transparent;
$tranparent-border-dark: #5f6368;

$custom-th-color: #e0e0e0;
$custom-th-color-dark: #19191c;

$custom-table-color: #ededed;
$custom-table-color-dark: #19191c;

$blue-comment-color: #e3eef6;
$blue-comment-color-dark: #255874;

$cdk-overlay-background: rgb(0 0 0 / 49%);
$cdk-overlay-background-dark: rgb(0 0 0 / 61%);

$fade-out-shadow: linear-gradient(to bottom, rgb(243 243 243 / 0%) 0%, #f5f5f5e0 75%);
$fade-out-shadow-dark: linear-gradient(to bottom, #20212400, #202124d9 75%);

$active-option: #f3f3f3;
$active-option-dark: #4d8cd6;

$carousel-arrows: #fff;
$carousel-arrows-dark: #c2c3c5;

$colors: (
    --primary-color: (
        $primary-color,
        $primary-color-dark
    ),
    --secondary-color: (
        $secondary-color,
        $secondary-color-dark
    ),
    --secondary-1-color: (
        $secondary-1-color,
        $secondary-1-color-dark
    ),
    --custom-secondary-color: (
        $custom-secondary-color,
        $custom-secondary-color-dark
    ),
    --custom-secondary-hover-color: (
        $custom-secondary-hover-color,
        $custom-secondary-hover-color-dark
    ),
    --primary-1-color: (
        $primary-1-color,
        $primary-1-color-dark
    ),
    --gray-1-color: (
        $gray-1-color,
        $gray-1-color-dark
    ),
    --gray-2-color: (
        $gray-2-color,
        $gray-2-color-dark
    ),
    --gray-3-color: (
        $gray-3-color,
        $gray-3-color-dark
    ),
    --gray-4-color: (
        $gray-4-color,
        $gray-4-color-dark
    ),
    --gray-5-color: (
        $gray-5-color,
        $gray-5-color-dark
    ),
    --gray-6-color: (
        $gray-6-color,
        $gray-6-color-dark
    ),
    --gray-7-color: (
        $gray-7-color,
        $gray-7-color-dark
    ),
    --gray-8-color: (
        $gray-8-color,
        $gray-8-color-dark
    ),
    --gray-9-color: (
        $gray-9-color,
        $gray-9-color-dark
    ),
    --gray-10-color: (
        $gray-10-color,
        $gray-10-color-dark
    ),
    --gray-11-color: (
        $gray-11-color,
        $gray-11-color-dark
    ),
    --gray-12-color: (
        $gray-12-color,
        $gray-12-color-dark
    ),
    --gray-hero-color: (
        $gray-hero-color,
        $gray-hero-color-dark
    ),
    --custom-gray-1-color: (
        $custom-gray-1-color,
        $custom-gray-1-color-dark
    ),
    --custom-gray-8-color: (
        $custom-gray-8-color,
        $custom-gray-8-color-dark
    ),
    --custom-gray-9-color: (
        $custom-gray-9-color,
        $custom-gray-9-color-dark
    ),
    --custom-gray-3-color: (
        $custom-gray-3-color,
        $custom-gray-3-color-dark
    ),
    --green-1-color: (
        $green-1-color,
        $green-1-color-dark
    ),
    --green-2-color: (
        $green-2-color,
        $green-2-color-dark
    ),
    --green-3-color: ($green-3-color,
        $green-3-color-dark),
    --green-4-color: ($green-4-color,
        $green-4-color-dark),
    --green-5-color: ($green-5-color,
        $green-5-color-dark),
    --green-6-color: ($green-6-color,
        $green-6-color-dark),
    --info-color: (
        $info-color,
        $info-color-dark
    ),
    --success-color: (
        $success-color,
        $success-color-dark
    ),
    --danger-color: (
        $danger-color,
        $danger-color-dark
    ),
    --warning-color: (
        $warning-color,
        $warning-color-dark
    ),
    --danger-darker-color: (
        $danger-darker-color,
        $danger-darker-color-dark
    ),
    --danger-lighter-color: (
        $danger-lighter-color,
        $danger-lighter-color-dark
    ),
    --info-hover-color: (
        $info-hover-color,
        $info-hover-color-dark
    ),
    --fb-color: (
        $fb-color,
        $fb-color-dark
    ),
    --reddit-color: (
        $reddit-color,
        $reddit-color-dark
    ),
    --email-color: (
        $email-color,
        $email-color-dark
    ),
    --twitter-color: (
        $twitter-color,
        $twitter-color-dark
    ),
    --fb-hover-color: (
        $fb-hover-color,
        $fb-hover-color-dark
    ),
    --reddit-hover-color: (
        $reddit-hover-color,
        $reddit-hover-color-dark
    ),
    --email-hover-color: (
        $email-hover-color,
        $email-hover-color-dark
    ),
    --twitter-hover-color: (
        $twitter-hover-color,
        $twitter-hover-color-dark
    ),
    --box-shadow: (
        $box-shadow,
        $box-shadow-dark
    ),
    --outer-shadow-1: (
        $outer-shadow-1,
        $outer-shadow-1-dark
    ),
    --tranparent-border: (
        $tranparent-border,
        $tranparent-border-dark
    ),
    --text-shadow: (
        $text-shadow,
        $text-shadow-dark
    ),
    --custom-th-color: (
        $custom-th-color,
        $custom-th-color-dark
    ),
    --custom-table-color: (
        $custom-table-color,
        $custom-table-color-dark
    ),
    --blue-comment-color: (
        $blue-comment-color,
        $blue-comment-color-dark
    ),
    --cdk-overlay-background: (
        $cdk-overlay-background,
        $cdk-overlay-background-dark
    ),
    --fade-out-shadow: (
        $fade-out-shadow,
        $fade-out-shadow-dark
    ),
    --active-option: (
        $active-option,
        $active-option-dark
    ),
    --carousel-arrows: (
        $carousel-arrows,
        $carousel-arrows-dark
    )
);
